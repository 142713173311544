import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import Landing from "./components/landing.js";
import Home from "./components/home.js";
import LearningCenter from "./components/learningCenter.js";
import "./App.scss";
import Plans from "./components/plans.js";
import Screen12 from "./components/screen-12";
import Screen13 from "./components/screen-13";
import Form from "./components/form.js";
import PartnerPlus from "./components/partnerPlus.js";
import PartnerPlusDownload from "./components/partnerPlusDownload.js";
import NewHomePage from "./components/NewHomePage.js";
function App() {
  resize();
  window.onresize = resize;

  return (
    <BrowserRouter>
      <AnimatedSwitch />
    </BrowserRouter>
  );
}

function resize() {
  let deviceWidth = document.documentElement.clientWidth;
  let fs = 100;

  if (deviceWidth <= 1920 && deviceWidth >= 901) {
    fs = (deviceWidth * 100 / 2200);
  } else if (deviceWidth < 900 && deviceWidth >= 541) {
    fs = (deviceWidth * 60 / 1000);
  } else if(deviceWidth <= 540) {
    fs = (deviceWidth * 82 / 540);
  }
  fs = (fs / 6.97) + "px" ;
  document.documentElement.style["font-size"] = fs;
  document.documentElement.style["-webkit-text-size-adjust"] = "none";
  window.dispatchEvent(new CustomEvent("app-resize", { fontSize: fs }));
}

const AnimatedSwitch = withRouter(({ location }) => (
  <TransitionGroup className="App">
    <Switch location={location}>
      <Route path="/" component={NewHomePage} exact  />
      {/* <Route path="/newhomepage" component={NewHomePage}/> */}
      <Route path="/learn-more" component={Landing} />


      <Route
        path="/partnerPlus"
        component={PartnerPlus }

      />
      <Route path="/partnerPlusDownload" component={PartnerPlusDownload} />
      <Route path="/:for/home" component={Home} />
      <Route path="/:for/form" component={Form} />
      <Route
        path="/:for/learning-center/:scrollId"
        component={LearningCenter}
      />
      <Route path="/:for/order" component={Plans} />
      
      <Route
        path="/blog/PM+WANI+Small+Businesses+PDO"
        component={Screen13}
      />
      <Route
        path="/blog/A+Digital+Revolution+next+500+million+India"
        component={Screen12}
      />
      
      
      <Route path="/form" component={Form} />
    </Switch>
  </TransitionGroup>
));

export default App;
