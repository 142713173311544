import { Component } from "react";
import axios from "axios";
import Header from "./header.js";
import "./landing.scss";
import YTWrapper from "./yt-video";
import logo2 from "../assets/svgs/wiom_hindi_eng_logo.svg";
import logo3 from "../assets/svgs/WIOM.svg";
import family_logo from "../assets/svgs/webpage_graphic.svg";
import family from "../assets/svgs/popup_graphic.svg";
import ladyPopup from "../assets/svgs/lady_popup.svg";
import playstoreIcon from "../assets/svgs/playstore_icon.svg";
import wiomlogoPinkBg from "../assets/svgs/wiom-logo-pink-bg.svg";
import crossIcon from "../assets/svgs/cross_icon.svg";
import delhiImg from "../assets/pngs/delhi_img.png";
import mumbaiImg from "../assets/pngs/mumbai_img.png";
import merathImg from "../assets/pngs/merath_img.png";
import gajiyabadImg from "../assets/pngs/gajiyabad_img.png";

export default class Landing extends Component {
  constructor() {
    super();
    this.state = {};
    this.state.ppDisplay = { display: "none" };
    this.state.servingCity = [
      "Delhi",
      "दिल्ली",
      "Mumbai",
      "मुंबई",
      "Meerut",
      "मेरठ",
      "Ghaziabad",
      "ग़ाज़ियाबाद",
      "Gurugram",
    ];
    this.state.canServe = true;
  }

  render = () => {
    const handleButtonClick = async () => {
      // Check if geolocation is available in the browser
      if ("geolocation" in navigator) {
        try {
          const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          });

          // If we reach here, we have user's location
          const { latitude, longitude } = position.coords;
          const url = `https://www.i2e1.in/Wiom/GetLocationInfo?lat=${latitude}&lng=${longitude}`;

          try {
            const response = await axios.get(url);
            let userCity = response.data.data;
            userCity = userCity.trim();

            // Since the condition now is: if we get location successfully, redirect directly
            window.location.href =
              "https://w.wiom.in/cstmr/download_wiom/index.html";
          } catch (error) {
            // Error getting data from the backend but we did get user's location.
            // In your requirement, you only need to redirect if location is accessible.
            // If there's an error from the backend, but we still got the location,
            // do you want to redirect or show the popup?
            // Since we got location, we should still redirect, as per your instructions.

            window.location.href =
              "https://w.wiom.in/cstmr/download_wiom/index.html";
          }
        } catch (error) {
          // This means user denied location or an error occurred in getting location.
          this.setState({ canServe: false });
          this.setState({ ppDisplay: { display: "block" } });
          console.error("Error getting location:", error);
        }
      } else {
        // Geolocation is not available
        this.setState({ canServe: false });
        this.setState({ ppDisplay: { display: "block" } });
        console.log("Geolocation is not available in this browser.");
      }
    };

    let learnMore = window.location.href.indexOf("learn-more") > -1;
    let appContent = null;
    let getInternetNowPopup = (
      <div className="popup" style={this.state.ppDisplay}>
        <div
          className="backdrop"
          onClick={(event) => {
            this.setState({ ppDisplay: { display: "none" } });
          }}
        ></div>
        <div
          className="popup_div1"
          style={{ position: "relative", paddingBottom: "18em" }}
        >
          {/* Move the WIOM logo to the top left using absolute positioning */}
          <img
            src={logo3}
            alt="Wiom-Logo"
            className="popup-hide-item"
            style={{
              position: "absolute",
              top: "2.7em",
              left: "2.7em",
              width: "10em",
              marginBottom: "3em",
            }}
          />
          <div className="popup1-title-div">
            <div style={{ marginTop: "4em" }}>
              <div className="popup1-title-1">
                पूरे घर का <br></br> अनलिमिटेड इंटरनेट
              </div>
              <div
                style={{
                  color: "white",
                  fontSize: "2.5em",
                  marginBottom: "2em",
                  whiteSpace: "nowrap",
                }}
              >
                केवल 1 महीने के पैसे दे कर शुरू
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                color: "white",
                width: "100%",
              }}
            >
              <div>
                <img
                  src={ladyPopup}
                  style={{
                    margin: "auto 0em auto 2em",
                    width: "25em",
                    filter: "contrast(.8) brightness(1.2)",
                  }}
                  alt=""
                />
              </div>
            </div>
          </div>
          {/* Move the crossIcon to the right using absolute positioning */}
          <img
            src={crossIcon}
            className="popup-hide-item"
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              width: "2em",
              height: "2em",
              margin: "1em",
              cursor: "pointer",
            }}
            onClick={() => {
              this.setState({ ppDisplay: { display: "none" } });
            }}
          />
          <div className="popup1-play-store-div">
            <div className="popup1-play-store-title">
              <div>बुक करने के लिए</div>
              <div>
                <img
                  src={wiomlogoPinkBg}
                  style={{ height: "1.8em", width: "1.8em" }}
                />
              </div>
              <div>Wiom App तुरंत डाउनलोड करें</div>
            </div>
            <div style={{ textAlign: "center", marginTop: "1em" }}>
              <a
                href="https://wiom.app.link/aQLtpt7cVCb"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={playstoreIcon}
                  style={{ width: "20em", cursor: "pointer" }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );

    if (!this.state.canServe) {
      getInternetNowPopup = (
        <div className="popup" style={this.state.ppDisplay}>
          {/* <div className="backdrop" onClick={(event) => {this.setState({ppDisplay: {display: 'none'}})}}></div> */}
          <div className="popup_div2">
            <div className="popup-title-text">
              हमारी सेवाएं अभी सिर्फ इन शहरों में उपलब्ध हैं।
            </div>
            <div
              style={{
                marginBottom: "3em",
                display: "flex",
                gap: "15px",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {/* <div className="city-img-div-class"> */}
              <img className="city-img-class" src={delhiImg} alt="Delhi" />
              <img
                className="city-img-class"
                src={mumbaiImg}
                style={{ width: "auto" }}
                alt="Mumbai"
              />
              {/* </div> */}
              {/* <div className="city-img-div-class"> */}
              <img
                className="city-img-class"
                src={merathImg}
                style={{ width: "auto" }}
                alt="Merath"
              />
              <img
                className="city-img-class"
                src={gajiyabadImg}
                style={{ width: "auto" }}
                alt="Ghaziabad"
              />
              {/* </div> */}
            </div>
            <div
              style={{
                color: "white",
                fontSize: "3em",
                marginTop: "1em",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                className="btn"
                onClick={() => {
                  window.location.href =
                    "https://w.wiom.in/cstmr/download_wiom/index.html";
                }}
              >
                {" "}
                ठीक है{" "}
              </button>
            </div>
          </div>
        </div>
      );
    }

    if (!learnMore) {
      appContent = (
        <div className="app-content">
          <div>
            <button
              onClick={handleButtonClick}
              className="button primary pink noshadow my-main-button "
            >
              Get Internet Now
            </button>
          </div>
        </div>
      );
    } else if (learnMore) {
      appContent = (
        <div className="app-learn-more">
          <div
            className="pm-wani-exp visible-in-small"
            style={{
              margin: 0,
            }}
          >
            PM WANI Scheme explained
          </div>
          <YTWrapper
            for="pdo"
            url="https://youtu.be/An2aQGHbUjE"
            thumb={`${process.env.PUBLIC_URL}/thumbs/learn_more.png`}
          >
            <a
              href="https://dot.gov.in/sites/default/files/2020_12_11%20WANI%20Framework%20Guidelines.pdf?download=1"
              target="_blank"
            >
              WANI Framework and Guidelines
            </a>
            <br />
            <a href="https://dot.gov.in/pm-wani" target="_blank">
              Department of Telecommunications website
            </a>
          </YTWrapper>
          <div className="hide-in-small">
            <div className="pm-wani-exp">PM WANI Scheme explained</div>
            <div className="this-scheme hide-in-small">
              This scheme will help small shopkeepers to provide Wi-Fi service.
              This will boost income as well as ensure your youth gets seemless
              internet connectivity
            </div>
            <div
              className="hide-in-small"
              style={{ color: "#214294", fontSize: "1.5em", marginTop: "2em" }}
            >
              - Prime Minister Narendra Modi
            </div>
            <a
              className="hide-in-small"
              href="https://twitter.com/narendramodi/status/1336705755404148736?lang=en"
              target="_blank"
            >
              Source: Offical Twitter Account
            </a>
          </div>
        </div>
      );
    }

    let appBottom = (
      <div className="app-bottom">
        <div className="mammi-papa-div">
          <span className="mummy_ka" style={{ fontSize: "2.5rem" }}>
            मम्मी का, पापा का, दीदी का, भाई का, दादा का, दादी का, चाचा का, भाभी
            का...
          </span>
        </div>
        <div style={{ textAlign: "center" }}>
          <div
            className="mobile-fix"
            style={{
              color: "#D92B90",
              fontSize: "4.2rem",
              fontWeight: "bold",
              paddingBottom: "15px",
            }}
          >
            ...सबका इंटरनेट
          </div>
          <div style={{ display: "inline-flex" }}>
            <img
              src={logo2}
              alt="Wiom-Logo"
              style={{ margin: "auto 2em", width: "38rem" }}
            />
          </div>
        </div>
        <div>
          <div
            className="spacing-fix"
            style={{ color: "white", fontSize: "3.4em" }}
          >
            सपनों से ज्यादा इंटरनेट
          </div>
          <img src={family_logo} alt="Wiom-Logo" style={{ width: "30em" }} />
        </div>
        {/* New black strip */}
        <div className="black-strip">
        <div className="copyright-text">
        Omnia Information Private Limited <br/>
        315/274, Westend marg, Saidulajaib, Saket, New Delhi-110030<br/>
        CIN: U72900DL2015PTC276515<br/>
        email:<a href="mailto:hr@wiom.in">hr@wiom.in</a>
  </div>
  <div className="copyright-text" style={{textAlign:"right"}}>
  <a target="_blank" href="tnc.html" class="terms-of-service" langId="term_condition">Terms of Service</a>
  <br/>
  Copyright ® 2023 Omnia Information Pvt. Ltd. All rights Reserved
  </div>


  
</div>

      </div>
    );

    return (
      <div
        className="landing"
        style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/appbg.jpg` }}
      >
        <Header nav="0" bg="no-bg" hideButton="true" />
        {appContent}
        {getInternetNowPopup}
        {appBottom}
      </div>
    );
  };
}
