import { React } from "react";
import "./statusCertification.scss";
import { Component } from "react";
import CongratsBaloons from "../assets/media/congrats-baloons.svg";
import PreviewCertificateDesktop from "../assets/media/certificate-desktop.png";
import PreviewCertificateMobile from "../assets/media/certificate-mobile.png";
import UnderProcess from "../assets/media/under_process.svg";
import NotRegistered from "../assets/media/not_registered.svg";

export default class StatusCertification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 'validate',
            routerState: '',
            macId: '',
            nasId: '',
            validMac: false,
            validNasid: false,
            fromi2e1: false,
            fromMac: false,
            shopName: ''
        };
        this.Partial = this.Partial.bind(this);
        this.validateMac = this.validateMac.bind(this);
        this.validateNasid = this.validateNasid.bind(this);
        this.checkStatus = this.checkStatus.bind(this);
        this.notRegistered = this.notRegistered.bind(this);
        this.underProcess = this.underProcess.bind(this);
        this.congrats = this.congrats.bind(this);
        this.previewCertificate = this.previewCertificate.bind(this);
        this.preview = this.preview.bind(this);
        this.download = this.download.bind(this);
        this.validate = this.validate.bind(this);
        this.applyNow = this.applyNow.bind(this);
        this.dim = this.dim.bind(this);
        this.unDim = this.unDim.bind(this);
        this.dimNasid = this.dimNasid.bind(this);
        this.unDimNasid = this.unDimNasid.bind(this);
        this.dimMac = this.dimMac.bind(this);
        this.unDimMac = this.unDimMac.bind(this);
    }
    dimNasid() {
        this.dim('nasid');
    }
    unDimNasid() {
        this.unDim('nasid');
        document.getElementById("whor").style.visibility = "visible";
    }
    dimMac() {
        this.dim('mac');
    }
    unDimMac() {
        this.unDim('mac');
        document.getElementById("whor").style.visibility = "visible";
    }
    dim(section) {
        if (section == "mac") {
            this.fromi2e1 = true;
            this.fromMac = false;
        } else {
            this.fromi2e1 = false;
            this.fromMac = true;
        }
        document.getElementById("whor").style.visibility = "hidden";
        document.getElementById(`input-${section}`).style.filter = "blur(5px)";
    }
    unDim(section) {
        document.getElementById(`input-${section}`).style.filter = "blur(0px)";
    }
    validateMac(event = null) {
        let that = this;
        this.setState({
            nasId: '',
            validNasid: false,
            macId: event? event.target.value.toLowerCase().replaceAll('-',':') : that.state.macId.toLowerCase().replaceAll('-',':')
        }, function () {
            var macSieve = /^[0-9a-f]{1,2}([\.:-])(?:[0-9a-f]{1,2}\1){4}[0-9a-f]{1,2}$/gmi;
            if (macSieve.test(that.state.macId.toLowerCase())) {
                that.setState({ validMac: true });
            } else {
                that.setState({ validMac: false });
            }
        });

    }
    validateNasid(event = null) {
        let that = this;
        this.setState({
            macId: '',
            validMac: false,
            nasId: event? event.target.value : that.state.nasId
        }, function () {
            if (!isNaN(parseInt(that.state.nasId)) && parseInt(that.state.nasId) > 0 && parseInt(that.state.nasId) <= 999999) {
                that.setState({ validNasid: true });
            } else {
                that.setState({ validNasid: false });
            }
        });
    }
    getWaniStatus(mac, status) {
        fetch("https://smokestack.i2e1.com/amiawani/"+mac)
            .then((response) => response.json())
            .then((data) => {
                    if (data && data.code != null && data.code == "1") {
                        return status(true);
                    } else {
                        return status(false);
                    }
                })
                .catch((error) => {
                    return status(false);
                });
    }
    getNasDetails(nasid, detailsCallback) {
        fetch("https://i2e1.in/wiom/getNasDetails?nasid="+nasid)
            .then((response) => response.json())
            .then(data => {
                if (data.data && data.data.macId != null) {
                        return detailsCallback(data.data);
                    } else {
                        return detailsCallback(null);
                    }
            })
            .catch((error) => {
                return detailsCallback(null);
            });

    }
    getNasidFromMac(mac, nasid) {
        fetch("https://i2e1.in/wiom/GetNasidFromMac?mac="+mac)
            .then((response) => response.json())
            .then((data) => {
                if (data.data && data.data != null && data.data.split(':').length > 0)
                    return nasid(parseInt(data.data.split(':')[0]));
                else
                    return nasid(null);
            })
            .catch((error) => {
                return nasid(null);
            });
    }
    downloadCertificate(nasid, e) {
        e.preventDefault();
        fetch("https://i2e1.in/wiom/GenerateCertificate?nasid="+nasid)
            .then((response) => response.json())
            .then((data) => {
                    if (data.data && data.data != null && data.data.Location != null)
                        window.open(data.data.Location);
                    else
                        alert('Please Try Again Later');
                })
                .catch((error) => {
                    alert('Please Try Again Later')
                });
    }
    checkStatus(event) {
        event.preventDefault();
        let that = this;
        this.validateMac();
        this.validateNasid();
        let state = this.state;
        if (state.validMac) {
            this.getNasidFromMac(state.macId, (nasid) => {
                this.getWaniStatus(state.macId, (status) => {
                    if (nasid && nasid > 0) {
                        // This is an i2e1 Location
                        if (status) {
                            //Show Certificate Preview
                            this.getNasDetails(nasid, (details) => {
                                if (details) {
                                    that.setState({
                                        shopName: details.shopName,
                                        date: "09/04/2021",
                                        macId: details.macId,
                                        nasId: nasid,
                                        fromi2e1: false,
                                        page: "congrats-i2e1"
                                    });
                                } else {
                                    that.setState({
                                        shopName: "Your Shop Name",
                                        date: "09/04/2021",
                                        macId: "XX:XX:XX:XX:XX:XX",
                                        nasId: nasid,
                                        fromi2e1: false,
                                        page: "congrats-i2e1"
                                    });
                                }   
                                    
                            })
                            event.preventDefault();
                        } else {
                            that.setState({
                                nasId: nasid,
                                fromi2e1: false,
                                page: "under-process"
                            });
                            event.preventDefault();
                        }
                    } else {
                        if (status)
                            that.setState({
                                fromi2e1: false,
                                page: "congrats-global"
                            });
                        else
                            that.setState({
                                fromi2e1: false,
                                page: "not-registered"
                            });
                    }
                });
            });
            
        } else if (state.validNasid) {
            this.getNasDetails(state.nasId, (details) => {
                if (details) {
                    this.getWaniStatus(details.macId, (status) => {
                        if (status) {
                            that.setState({
                                macId: details.macId,
                                shopName: details.shopName,
                                nasId: state.nasId,
                                fromi2e1: true,
                                page: "congrats-i2e1"
                            });
                        } else {
                            that.setState({
                                fromi2e1: true,
                                page: "under-process"
                            });
                        }
                    });
                } else {
                    that.setState({
                        fromi2e1: true,
                        page: "not-registered"
                    });
                }
            });
        } else {
            alert("No Valid Input");
            this.setState((state) => {
                return { nasId: '', macId: '', validNasid: false, validMac: false };
            });
        }
    }
    applyNow() {
        // Navigate to the appropriate page via routing
        window.open('https://forms.gle/GjbCX7a14CRrR7Ew5');
    }
    notRegistered() {
        return (
            <div className="notregistered">
                <img className="image" src={NotRegistered} alt="et"></img>
                <div className="subtitle">
                    You are not registered as PDO in PM-WANI Central Registry.
                </div>
                <div className="subtext">
                    Want to become PDO?
                </div>
                <input type="button" className="cta" value="Apply Now" onClick={this.applyNow} />
            </div>
        );
    }
    underProcess() {
        return (
            <div className="underprocess-container">
                <img className="underprocess-image" src={UnderProcess} alt="et"></img>
                <div className="underprocess-subtitle">
                    Your registration is under process.
                </div>
                <div className="underprocess-subtext">
                    Please recheck in couple of days<br/>
                    or contact us at <a href="mailto:talk2wiom@wiom.in">talk2wiom@wiom.in</a>
                </div>
            </div>
        );
    }
    congrats(fromi2e1 = false) {
        return (
            <div className="congrats-container">
                <img className="congrats-image" src={CongratsBaloons} alt="et"></img>
                <div className="congrats-title">
                    Congratulations!
                </div>
                <div className="congrats-subtitle">
                    You are registered as PDO in PM-WANI Central Registry.
                </div>
                {
                    fromi2e1 && <input type="button" className="congrats-cta" value="View Certificate" onClick={(e) => this.previewCertificate(this.state.nasId, e)} />
                }
            </div>
        );
    }
    previewCertificate(nasid, e) {
        e.preventDefault();
        this.setState({
            nasId: nasid,
            page: "preview"
        });
    }
    preview() {
        return (
            <div className="preview-container">
                <div className="preview-image-container">
                    <svg className="preview-image-desktop"
                        alt="certificate preview"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <pattern id="background-desktop" x="0" y="0" patternUnits="userSpaceOnUse" height="625" width="874">
                            <image x="0" y="0" href={ PreviewCertificateDesktop }></image>
                            </pattern>
                        </defs>
                        <rect width="874" height="625" fill="url(#background-desktop)" />
                        <text x="530" y="305" 
                            fontSize="24" fontFamily="Arial" fontStyle="italic" fontWeight="bold" 
                            textAnchor="middle" fill="black" id="svg_shop_name">{ this.state.shopName }</text>
                        <text x="730" y="371" 
                            fontSize="20" fontFamily="Arial"
                            textAnchor="middle" fill="black" id="svg_mac">{ this.state.macId }</text>
                        <text x="185" y="525" 
                            fontSize="24" fontFamily="Arial" fontStyle="italic" fontWeight="bold" 
                            textAnchor="middle" fill="black" id="svg_date">{ this.state.date }</text>
                    </svg>
                    <svg className="preview-image-mobile"
                        alt="certificate preview"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <pattern id="background-mobile" x="0" y="0" patternUnits="userSpaceOnUse" height="250" width="350">
                            <image x="0" y="0" href={ PreviewCertificateDesktop } height="250" width="350"></image>
                            </pattern>
                        </defs>
                        <rect width="350" height="250" fill="url(#background-mobile)" />
                        <text x="195" y="123" 
                            fontSize="7" fontFamily="Arial" fontStyle="italic" fontWeight="bold" 
                            textAnchor="middle" fill="black" id="svg_shop_name">{ this.state.shopName }</text>
                        <text x="290" y="148" 
                            fontSize="7" fontFamily="Arial"
                            textAnchor="middle" fill="black" id="svg_mac">{ this.state.macId }</text>
                        <text x="75" y="210" 
                            fontSize="7" fontFamily="Arial" fontStyle="italic" fontWeight="bold" 
                            textAnchor="middle" fill="black" id="svg_date">{ this.state.date }</text>
                    </svg>
                </div>
                <input type="button" className="preview-cta" value="Download Certificate" onClick={(e) => this.downloadCertificate(this.state.nasId, e)} />
            </div>
        );
    }
    download() {
        fetch("https://i2e1.in/wiom/GenerateCertificate?nasid="+this.state.nasId)
        .then((response) => {
            if (response.data.Location)
                window.open(response.data.Location);
        })
        .catch((error) => {
            alert("Please Try Again Later");
        });
    }
    validate() {
        return (
            <div className="check-status">
                <div id="input-mac">
                    <div className="title">Enter Mac</div>
                    <input onFocus={ this.dimNasid } onBlur={ this.unDimNasid } className="input" placeholder="e.g. 00:1b:63:84:45:e6" value={this.state.macId} onChange={this.validateMac} />
                </div>
                <span id="whor" className="or">or</span>
                <div id="input-nasid">
                    <div className="title">Nas ID</div>
                    <input onFocus={ this.dimMac } onBlur={ this.unDimMac } className="input" placeholder="e.g.7892" value={this.state.nasId} onChange={this.validateNasid} />
                </div>

                <br />
                <input type="submit" className="check-now" onClick={(e) => this.checkStatus(e)} value="Check Now" />
            </div>
        );
    }
    Partial(page) {
        switch (page) {
            case 'congrats-i2e1':
                return this.congrats(true);
            case 'congrats-global':
                return this.congrats(false);
            case 'preview':
                return this.preview();
            case 'under-process':
                return this.underProcess();
            case 'not-registered':
                return this.notRegistered();
            default:
            case 'validate':
                return this.validate();
        }
    }
    render() {
        return (
            <div className="status-certification">
                {this.Partial(this.state.page)}
            </div>
        );
    }
}
